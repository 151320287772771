<template>
  <vx-card title="All trips">

    <div class="flex justify-between">

      <div style="width: 200px">
        <h6>Number of rows</h6>
        <v-select style="margin-bottom: -12px !important;" :clearable="false" class="mb-base w-full" @input="setMax"
                  v-model="maxItem" :options="options"

        >

        </v-select>
      </div>
      <div class="flex items-center">
        <vs-button size="small" @click="refresh">
          <feather-icon icon="RefreshCwIcon"></feather-icon>
        </vs-button>
        <div style="width: 200px;">
          <v-select
            v-model="selectedShip"
            :options="ships"
          />
        </div>
      </div>
    </div>

    <vs-table pagination :max-items="maxItem" search :data="filteredData">

      <template slot="thead">
        <vs-th sort-key="number">Trip #</vs-th>
        <vs-th sort-key="ship">Ship</vs-th>
        <vs-th sort-key="type">Type</vs-th>
        <vs-th sort-key="start_date">Start Date</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="created_by">Created by</vs-th>
        <vs-th sort-key="income">Income</vs-th>
        <vs-th sort-key="expense">Expense</vs-th>
        <vs-th sort-key="profit">Profit</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
        >

          <vs-td :data="data[indextr].number">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].number }}</span>
          </vs-td>

          <vs-td :data="data[indextr].ship">
            <feather-icon icon="CheckIcon"
            class="text-sm text-success"
                          v-if="tr.is_completed"
            ></feather-icon>
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].ship }}</span>
          </vs-td>

          <vs-td :data="data[indextr].type">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].type }}</span>
          </vs-td>

          <vs-td :data="data[indextr].start_date">
            <span @click="showTripFn(data[indextr].id)"> {{ data[indextr].start_date | formatDate }}</span>
          </vs-td>
          <vs-td :data="data[indextr].status">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].status }}</span>
          </vs-td>
          <vs-td :data="data[indextr].created_by">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].created_by }}</span>
          </vs-td>
          <vs-td :data="data[indextr].income">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].income|currency }}</span>
          </vs-td>
          <vs-td :data="data[indextr].expense">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].expense|currency }}</span>
          </vs-td>
          <vs-td :data="data[indextr].profit">
            <span @click="showTripFn(data[indextr].id)">{{ data[indextr].profit|currency }}</span>
          </vs-td>
          <vs-td :data="data[indextr].id">
            <vs-dropdown color="success" style="cursor: pointer" vs-trigger-click>
              <vs-button class="btn-drop" type="filled" icon="more_horiz"></vs-button>
              <vs-dropdown-menu style="width: 200px">
                <vs-dropdown-item v-if="$auth.check(['income_add'])" @click="showTripFn(data[indextr].id)">View
                </vs-dropdown-item>
                <vs-dropdown-item v-if="$auth.check(['income_add'])" @click="addIncome(data[indextr].id)">Add Income
                </vs-dropdown-item>
                <vs-dropdown-item v-if="$auth.check(['expense_add'])" @click="addExpense(data[indextr].id)">Add
                  Expense
                </vs-dropdown-item>
                <vs-dropdown-item v-if="$auth.check(['income_edit'])">
                  <router-link :to="'trip/edit?id='+data[indextr].id">Edit</router-link>
                </vs-dropdown-item>

                <vs-dropdown-item v-if="$auth.check(['income_edit'])">
                  <a href="javascript:void(0)" @click="completeTrip(data[indextr].id)">
                    {{data[indextr].is_completed ? 'Mark As Incomplete' : 'Mark As Complete'}}</a>
                </vs-dropdown-item>

                <vs-dropdown-item divider v-if="$auth.check(['income_delete'])"
                                  @click="confirmDelete(data[indextr].id)">Delete
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
    <div class="demo-alignment">
      <vs-popup class="holamundo" v-if="popupActive" :title="transactionForm.action" :active.sync="popupActive">
        <vx-input-group class="mb-base">
          <datepicker v-if="$auth.check(['date'])" class="text-center" v-model="transactionForm.date"></datepicker>
        </vx-input-group>
        <vx-input-group class="mb-base">
          <vs-input label="Details" v-validate="'required'" name="detail" v-model="transactionForm.detail"
                    placeholder="Detail"/>
          <span class="text-danger text-sm" v-show="errors.has('detail')">{{ errors.first('detail') }}</span>
        </vx-input-group>
        <vx-input-group class="mb-base">
          <vs-input v-validate="'required|numeric'" name="amount" label="Amount" v-model="transactionForm.amount"
                    placeholder="Amount"/>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
        </vx-input-group>
        <div class="flex" v-if="transactionForm.attachments">
          <div class="mail__attachment" v-for="(attachment, index) in transactionForm.attachments">
            <vs-chip color="primary" @click="removeAttachment(index)" class="px-4 py-2" closable><p>
              {{ attachment.attachment_name }}</p></vs-chip>
          </div>
        </div>
        <vs-upload :key="uploadKey" @on-success="uploadSuccess" @on-error="uploadFailed" fileName="attachment[]"
                   :action="baseUrl+'/api/upload'" :automatic="true" :headers="{Authorization:'Bearer '+$auth.token()}"
                   multiple text="Attachments" :show-upload-button="true"/>
        <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Save</vs-button>

      </vs-popup>
    </div>
    <div class="demo-alignment">
      <vs-popup class="holamundo" title="Are you sure?" :active.sync="deleteConfirmation">
        <p>This action can not undone! If this is mistake click cancel</p>
        <div class="flex justify-between flex-wrap">
          <vs-button class="mt-4 shadow-lg" type="gradient" @click="deleteTr(deleteId)" color="danger">Delete
          </vs-button>
          <vs-button class="mt-4 shadow-lg" type="gradient" @click="deleteConfirmation=false" color="success">Cancel
          </vs-button>
        </div>

      </vs-popup>
    </div>
    <router-link to="/trip/add">
      <vs-button class="floating-btn" color="success" type="gradient" icon-pack="feather" icon="icon-plus"></vs-button>
    </router-link>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import TripView from '../components/TripView';

export default {
  data() {
    return {
      showTrip: false,
      tripData: null,
      popupActive: false,
      deleteConfirmation: false,
      deleteId: '',
      uploadKey: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      transactionForm: {
        attachments: [],
        action: '',
        type: '',
        trip_id: '',
        date: new Date(),
        detail: '',
        amount: '',
      },
      users: [],
      options: [
        10,
        20,
        50,
        100
      ],
      maxItem: localStorage.getItem('maxItem') ? localStorage.getItem('maxItem') : 10,
      ships: ["All", "Hasan Hamim 1", "Hasan Hamim 3"],
      selectedShip: "All",
      currentPage: 2,
      filteredData: [],
    }
  },
  watch: {
    selectedShip: function (t) {
      this.onShipSelectionUpdate(t)
    }
  },
  methods: {
    uploadSuccess(event) {
      this.transactionForm.attachments = this.transactionForm.attachments.concat(JSON.parse(event.currentTarget.response));
      this.uploadKey++;
    },
    removeAttachment(i) {
      this.axios.delete('/delete/' + this.transactionForm.attachments[i].storage_name).then(({data}) => {
        this.transactionForm.attachments.splice(i, 1);
      });
    },
    uploadFailed(event) {
      console.log(event);
    },
    confirmDelete(id) {
      this.deleteConfirmation = true;
      this.deleteId = id;
    },
    deleteTr(id) {
      this.deleteConfirmation = false;
      if (this.$auth.check(['trip_delete'])) {
        this.axios.delete('trip/' + id)
          .then(res => {
            if (res.data.notify) {
              this.$vs.notify({
                title: res.data.notify.title,
                text: res.data.notify.message,
                color: res.data.notify.type
              })
            }
            this.update();

          })
      } else {
        this.$vs.notify({
          title: 'Unauthorised Access !',
          text: 'You are not authorized for some information.',
          color: 'danger'
        })
      }

    },
    showTripFn(tripId) {
      this.$router.push('/trip/' + tripId);
    },
    addExpense(tripId) {
      this.transactionForm.action = 'Add Expense';
      this.transactionForm.type = 'expense';
      this.transactionForm.trip_id = tripId;
      this.popupActive = true;
    },
    addIncome(tripId) {
      this.transactionForm.action = 'Add Income';
      this.transactionForm.type = 'income';
      this.transactionForm.trip_id = tripId;
      this.popupActive = true;
    },
    submitForm() {
      this.popupActive = false;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.transactionForm.date = this.$options.filters.dateToString(this.transactionForm.date);
          console.log(this.transactionForm);
          this.axios.post('transaction/add', this.transactionForm)
            .then(res => {
              if (res.data.notify) {
                this.$vs.notify({
                  title: res.data.notify.title,
                  text: res.data.notify.message,
                  color: res.data.notify.type
                })
              }
              if (res.data.status == 'success') {
                this.transactionForm = {
                  action: '',
                  type: '',
                  trip_id: '',
                  date: new Date(),
                  detail: '',
                  amount: '',
                }
              }
              this.uploadKey++;
              this.$validator.reset();
              this.update();

            })
        } else {
          // form have errors
        }
      })
    }
    ,
    setMax() {
      if (this.maxItem < 10)
        this.maxItem = 10;
      localStorage.setItem('maxItem', this.maxItem);
    },
    update: function () {
      var t = this;

      this.axios.get("/trips").then(function (e) {
        t.users = e.data;
        t.selectedShip = localStorage.getItem("selectedShip") ? localStorage.getItem("selectedShip") : "All";
        t.filteredData = e.data;
        t.currentPage = localStorage.getItem("currentPage") ? localStorage.getItem("currentPage") : 1
      })
    },
    onShipSelectionUpdate: function (t) {

      localStorage.setItem("selectedShip", t);

      if (t && t === 'All') {
        this.filteredData = this.users
      }

      this.filteredData = this.users.filter(function (e) {
        return e.ship === t
      });
    },
    pageChange: function (t) {
      this.currentPage = t;
      localStorage.setItem("currentPage", t)
    },
    refresh: function () {
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("selectedShip", "All");
      this.currentPage = 1;
      this.selectedShip = "All"
    },
    completeTrip(id) {
      this.axios.post('trips/' + id + '/complete').then(res => {
        if (res.data.notify) {
          this.$vs.notify({
            title: res.data.notify.title,
            text: res.data.notify.message,
            color: res.data.notify.type
          })
        }
        this.update();
      })

    }
  },
  mounted() {
    this.update();
    Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer ' + this.$auth.token();
    Echo.private('App.User.' + this.$auth.user().id)
      .notification((notification) => {
        this.update();
      });
  },
  components: {
    Datepicker,
    'v-select': vSelect,
    'trip-view': TripView
  }
}
</script>
<style scoped>
.vdp-datepicker input {
  width: 100%;
}

/*.select_input{*/
/*padding-top: 10px;*/
/*}*/
/*::v-deep .vs-popup--content {*/
/*overflow: inherit;*/
/*}*/
</style>
